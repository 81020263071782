import { DocumentResource } from '@/resources/DocumentResource'

export const RiderResource = {
  id: '',
  role: '',
  organization_id: '',
  full_name: '',
  phone_number: '',
  email: '',
  user_status: '',
  invitation_code: '',
  profile: {
    profile_pic: '',
    gender: null,
    ranger_job_type: null,
    birthday: null,
    height: null,
    weight: null,
    balance: null,
    num_of_trips: 0,
    total_calories: '0.00',
    earning_from_invitations: 0,
  },
  assigned_fleets: [],
  payment_info: {},
  document_info: [{ ...DocumentResource }],
  address_info: {
    current_address_country: null,
    current_address_full: null,
    current_address_zip_code: null,
    permanent_address_country: null,
    permanent_address_full: null,
    permanent_address_zip_code: null,
  },
  last_otp_code: '',
  last_ip_address: '',
  last_location: '',
  is_doc_verified: '',
  is_email_confirmed: '',
}

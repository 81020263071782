<template>
  <content-section>
    <div
      class="flex flex-col mt-4 lg:items-center md:items-start lg:flex-row md:flex-col "
    >
      <display-avatar
        :avatar="avatar"
        :badge="badge"
        :badgeVariant="badgeVariant"
      />
      <section
        class="items-center justify-between w-full mt-4 md:flex sm:block lg:flex-no-wrap lg:flex-row sm:mt-0"
        :class="slotContainerClass"
      >
        <slot name="left w-1/4 mr-2">
          <div
            class="flex flex-col md:mt-4 sm:ml-4 lg:mt-0 "
            :class="leftSlotClass"
          >
            <div class="flex items-center w-full gap-1">
              <h2 class="text-2xl font-semibold capitalize break-words">
                {{ name }}
              </h2>
              <img
                v-if="isBlacklisted"
                v-tooltip.bottom="`Blacklisted`"
                class="w-5 h-5 "
                src="@/assets/icon/blacklist_user.svg"
              />
              <!-- <div v-if="badge" class="px-3 py-px ml-2 text-sm text-gray-600 bg-gray-300 rounded-full">
                {{ badge }}
              </div> -->
            </div>
            <div class="flex flex-col w-full mt-2 mr-3">
              <div
                v-if="email"
                class="flex items-center text-gray-600 lg:space-x-0 sm:space-x-1 "
              >
                <i class="fas fa-envelope"></i>
                <span class="pl-1 lowercase truncate">{{ email }}</span>
              </div>
              <div
                v-if="phone"
                class="flex items-center text-gray-600 lg:space-x-0 sm:space-x-1 md:ml-0"
              >
                <i class="fas fa-phone-alt"></i>
                <span class="pl-1 mt-px truncate"> {{ phone }}</span>
              </div>
              <div
                v-if="website"
                class="flex items-center text-gray-600 md:ml-0 lg:space-x-0 sm:space-x-1 sm:ml-4"
              >
                <i class="fas fa-globe-asia"></i>
                <span class="pl-1 truncate">{{ website }}</span>
              </div>
            </div>
          </div>
        </slot>
        <!-- <slot name="right">
          <div
            class="flex flex-col mt-4 sm:ml-4 sm:mt-0"
            :class="rightSlotClass"
          ></div>
        </slot> -->
        <div v-if="marketplaceCardInfo" class="flex flex-wrap gap-4 right-box">
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-blue-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Vehicle</p>
              <i class="text-blue-500 fas fa-bicycle"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ marketplaceCardInfo.number_of_vehicles }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-red-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Order</p>
              <i class="text-orange-400 fas fa-sitemap"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ marketplaceCurrencySymbol }}
              {{ marketplaceCardInfo.order_value }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-orange-300 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Collected</p>
              <i class="text-orange-600 fas fa-money-bill-wave"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ marketplaceCurrencySymbol }}
              {{ marketplaceCardInfo.collected }}
            </div>
          </div>
        </div>
        <div v-if="fleetCardInfo" class="flex flex-wrap gap-4 right-box">
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-blue-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Vehicle</p>
              <i class="text-blue-500 fas fa-user-friends"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ fleetCardInfo.total_vehicle }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-red-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Rider</p>
              <i class="text-orange-500 fas fa-user-friends"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ fleetCardInfo.total_rider }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-orange-300 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Trip</p>
              <i class="text-orange-400 fas fa-sitemap"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ fleetCardInfo.total_trip }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-red-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Revenue</p>
              <i class="text-orange-600 fas fa-money-bill-wave"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ fleetRevenueSymbol }} {{ fleetCardInfo.total_revenue }}
            </div>
          </div>
        </div>
        <div v-if="operatorCardInfo" class="flex flex-wrap gap-4 right-box">
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-variant-blue rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Tasks</p>
              <i class="text-blue-500 fas fa-stream"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ operatorCardInfo.total_task }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-variant-yellow rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Ongoing</p>
              <i class="text-orange-500 fas fa-clipboard"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ operatorCardInfo.ongoing_task_count }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-variant-orange rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Rebalancing</p>
              <i class="text-orange-400 fas fa-tools"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ operatorCardInfo.rebalancing_task_count }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-variant-purple rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Charging</p>
              <i class="text-orange-600 fas fa-charging-station"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ operatorCardInfo.charging_task_count }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-variant-green rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Completed</p>
              <i class="text-orange-600 fas fa-clipboard-check"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ operatorCardInfo.completed_task_count }}
            </div>
          </div>
        </div>
        <div v-if="agentCardInfo" class="flex flex-wrap gap-4 right-box">
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-blue-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Balance</p>
              <i class="text-blue-500 fas fa-user-friends"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ symbol }}{{ agentCardInfo.total_balance }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-red-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Credit</p>
              <i class="text-orange-500 fas fa-user-friends"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ symbol }}{{ agentCardInfo.total_credit }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-orange-300 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Debit</p>
              <i class="text-orange-400 fas fa-sitemap"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ symbol }}{{ agentCardInfo.total_debit }}
            </div>
          </div>
        </div>
        <div v-if="franchiseCardInfo" class="flex flex-wrap gap-4 right-box">
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-blue-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Vehicle</p>
              <i class="text-blue-500 fas fa-user-friends"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ franchiseCardInfo.total_vehicle }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-red-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Rider</p>
              <i class="text-orange-500 fas fa-user-friends"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ franchiseCardInfo.total_rider }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-orange-300 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Trip</p>
              <i class="text-orange-400 fas fa-sitemap"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ franchiseCardInfo.total_trip }}
            </div>
          </div>
          <div
            class="flex flex-col justify-between w-40 h-20 px-4 py-3 border-t border-b border-l border-r border-red-200 rounded-xl"
          >
            <div class="flex justify-between">
              <p class="text-sm text-gray-500">Total Revenue</p>
              <i class="text-orange-600 fas fa-money-bill-wave"></i>
            </div>
            <div class="font-bold text-oDark">
              {{ symbol }} {{ franchiseCardInfo.total_revenue }}
            </div>
          </div>
        </div>
        <div v-if="riderCardInfo">
          <div
            class="flex  flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row flex-wrap  justify-end"
          >
            <SummaryCardAlt
              title="Total Trips"
              :value="`${riderCardInfo.total_trips || 0}`"
              variant="light-blue"
            >
              <template slot="icon">
                <i class="text-blue-500 fas fa-sitemap"></i>
              </template>
            </SummaryCardAlt>

            <SummaryCardAlt
              title="Total Balance"
              :value="`${riderCardInfo.total_balance || 0}`"
              variant="orange"
              :symbol="symbol"
            >
              <template slot="icon">
                <i class="text-orange-500 fas fa-money-bill-wave"></i>
              </template>
            </SummaryCardAlt>

            <SummaryCardAlt
              title="Total Points"
              :value="`${riderCardInfo.total_points || 0}`"
              variant="light-orange"
            >
              <template slot="icon">
                <i class="text-orange-400 fas fa-wallet"></i>
              </template>
            </SummaryCardAlt>

            <SummaryCardAlt
              title="Total Calories"
              :value="
                `${
                  parseFloat(riderCardInfo.total_calories) > 0
                    ? riderCardInfo.total_calories
                    : '--'
                }`
              "
              variant="deep-orange"
            >
              <template slot="icon">
                <i class="text-orange-700 fas fa-fire"></i>
              </template>
            </SummaryCardAlt>
          </div>
        </div>
      </section>
    </div>
    <div class="mt-5 mb-3 full-mode" v-if="tabs">
      <anchor-tabs :tabs="tabs" />
    </div>
    <div class="mt-5 mb-3 responsive-mode" v-if="tabs">
      <t-rich-select
        :options="tabs"
        valueAttribute="href"
        textAttribute="text"
        :hideSearchBox="true"
        @change="changeRoute"
        v-model="routeValue"
      />
    </div>
  </content-section>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import DisplayAvatar from '@/components/ui/DisplayAvatar'
import AnchorTabs from '@/components/tabs/AnchorTabs'
export default {
  name: 'DetailsPageHeader',
  props: {
    showCreditInfo: {
      required: false,
      // type : Object,
    },
    avatar: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
      default: '--',
    },
    badge: {
      required: false,
      type: String,
      default: '--',
    },
    badgeVariant: {
      required: false,
      type: String,
      default: 'fallback',
      validator(val) {
        const whitelist = ['fallback', 'orange', 'green', 'red']
        return whitelist.indexOf(val) !== -1
      },
    },
    status: {
      required: false,
      type: String,
      default: 'D',
    },
    phone: {
      required: false,
      type: String,
      default: '',
    },
    email: {
      required: false,
      type: String,
      default: '',
    },
    website: {
      required: false,
      type: String,
      default: '',
    },
    tabs: {
      required: false,
      type: Array,
    },
    slotContainerClass: {
      required: false,
      type: String,
    },
    leftSlotClass: {
      required: false,
      type: String,
    },
    rightSlotClass: {
      required: false,
      type: String,
    },
    fleetCardInfo: {
      required: false,
      type: Object,
    },
    marketplaceCardInfo: {
      required: false,
      type: Object,
    },
    agentCardInfo: {
      required: false,
      type: Object,
    },
    franchiseCardInfo: {
      required: false,
      type: Object,
    },
    operatorCardInfo: {
      required: false,
      type: Object,
    },
    riderCardInfo: {
      required: false,
      type: Object,
    },
    symbol: {
      required: false,
      type: String,
    },
    fleetRevenueSymbol: {
      required: false,
      type: String,
    },
    marketplaceCurrencySymbol: {
      required: false,
      type: String,
    },
    isBlacklisted: {
      required: false,
      type: Boolean,
    },
  },
  components: {
    ContentSection,
    DisplayAvatar,
    AnchorTabs,
    SummaryCardAlt: () => import('@/components/cards/SummaryCardAlt'),
  },
  created() {
    this.routeValue = this.tabs?.filter(
      (item) => item.text === 'Profile'
    )[0].href
  },
  mounted() {
    this.routeValue =
      this.$router.currentRoute.path ??
      this.tabs?.filter((item) => item.text === 'Profile')[0].href
  },
  data() {
    return {
      routeValue: null,
    }
  },
  computed: {
    getBadgeVariant() {
      const aliases = {
        H: 'orange',
        A: 'green',
        D: 'red',
      }
      return this.status in aliases ? aliases[this.status] : 'fallback'
    },
  },
  methods: {
    changeRoute(event) {
      this.$router.push({ path: event })
    },
  },
}
</script>
<style>
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.w-40 {
  width: 11rem;
}
.border-variant-green {
  border-color: #09aa35 !important;
}
.border-variant-red {
  border-color: #d90a20 !important;
}
.border-variant-yellow {
  border-color: #ffed00 !important;
}
.border-variant-blue {
  border-color: #0053ff !important;
}
.border-variant-orange {
  border-color: #f67606 !important;
}
.border-variant-purple {
  border-color: #7d3cf8 !important;
}
</style>

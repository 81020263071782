var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.shouldDisplayDropdown)?_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"trip-status-button vld-parent",attrs:{"aria-label":"Profile Status","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.isLoading)?_c('loading',{attrs:{"height":25,"opacity":0,"color":"#7d3cf8","active":true,"loader":"bars","is-full-page":false}}):_c('div',{staticClass:"flex items-center justify-center w-8 h-8 bg-green-500 rounded-full"},[_c('img',{attrs:{"src":_vm.getIconSrc}})]),_c('span',{staticClass:"ml-2 font-semibold",class:_vm.getStatusClass},[_vm._v(" "+_vm._s(_vm.getStatusText)+" ")]),_c('i',{staticClass:"pr-2 ml-auto text-lg text-gray-500 fas fa-chevron-down"})],1)]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-1 bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{on:{"click":function($event){return _vm.action('complete')}}},[_vm._v(" Force End ")]),_c('t-dropdown-item',{on:{"click":function($event){return _vm.action('cancel')}}},[_vm._v(" Force Cancel ")])],1)]}}],null,false,493492603)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="w-full overflow-x-auto overflow-y-hidden">
    <nav class="flex flex-wrap ">
      <anchor-tab-item
        v-for="(item, index) in tabs"
        :key="index"
        :item="item"
      />
    </nav>
    <!-- <div class="h-1 bg-gray-100 shadow-2xl" /> -->
  </div>
</template>

<script>
import AnchorTabItem from './AnchorTabItem.vue'
export default {
  components: { AnchorTabItem },
  props: ['tabs'],
}
</script>

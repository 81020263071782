<template>
  <router-link
    :to="item.href"
    class="whitespace-nowrappx-0 ml-0 mr-6 py-3 text-xs font-medium text-center text-gray-500 uppercase transition duration-200 ease-in-out border-b-2 border-transparent cursor-pointer hover:text-gray-700 focus:outline-none"
  >
    {{ item.text }}
  </router-link>
</template>

<script>
export default {
  props: {
    item: undefined,
  },
  computed: {
    getClasses: function() {
      return {}
    },
  },
}
</script>

<style scoped>
.router-link-active {
  @apply text-gray-800 border-gray-800 font-bold;
}
</style>
